import { Button, useToast } from '@chakra-ui/react'
import { IconPlus, IconShoppingCart } from '@tabler/icons';
import { gql, useMutation } from '@apollo/client'

import React from 'react'
import { useAppState } from './context'

const ADD_TO_CART = gql`
mutation ATC($input: AddToCartInput  = {productId: 3563}) {
  addToCart(input: $input){
    cart {
      subtotal
      total
      shippingTotal
      contents {
        itemCount
        nodes {
          product {
            node {
              name
              sku
              databaseId
              ... on SimpleProduct {
                price
                regularPrice
              }
            }
          }
        }
      }
    }
  }
}
`

const AddToCart: React.FC<{ productId: number }> = ({ productId }) => {
  const toast = useToast()
  const { setCart } = useAppState()
  const [addToCart, { data, loading, error }] = useMutation(ADD_TO_CART, {
   
    onCompleted: ({ addToCart }) => {
      toast({
        title: 'Added to cart',
        status: 'success',
      })
      setCart(addToCart.cart)
    },
    onError: () => {
    console.log(data, error)
      toast({
        title: 'Error',
        description: 'There was an error adding your product',
        status: 'error',
      })
    },
  })

  function handleAddToCart() {
    addToCart({
      variables: { input: { productId, quantity: 1 } }
    })
  }

  return (
    <Button
      onClick={() => handleAddToCart()}
      isLoading={loading}
      loadingText="Adding to cart"
      colorScheme="cdicyan"
      variant="solid"
      size="md"
      width="full"
      leftIcon={<IconPlus height="16px" width="16px" />}
    >
      Add To Cart
    </Button>
  )
}


export default AddToCart
